import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IListResult } from '../../../models/list-result';
import { IAnswer } from '../../../models/answer';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

function getQuery() {

    return gql`
        query ResponsesGetPaged($page: Int, $uid: String, $searchText: String, $orderBy: String, $pageSize: Int) {
            responses_paged(page: $page, uid: $uid, search_text: $searchText, order_by: $orderBy, page_size: $pageSize) {
                count,
                page,
                pageSize: page_size,

                data {
                    uid,
                    answer,
                    createdAt: created_at,
                    upvoteCount: upvote_count,
                    downvoteCount: downvote_count,
                    adminSubmitted: admin_submitted
                }
            }
        }
    `;
}

export async function getAnswersPaged(params: {
    page: number,
    uid?: string,
    searchText?: string,
    orderBy?: string,
    pageSize?: number,
}): Promise<IListResult<IAnswer>> {
    const result: FetchResult<{ responses_paged: IListResult<IAnswer> }> = await graphqlClient.query({
        query: getQuery(),
        variables: {
            page: params.page,
            uid: params.uid,
            searchText: params.searchText,
            orderBy: params.orderBy,
            pageSize: params.pageSize || 20,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.responses_paged, ['__typename']);
}
