import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAnswersPaged } from '../../../api/actions/answers/answers-get-paged';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { Popup } from '../../../components/shared/popup/popup';
import { SearchField } from '../../../components/shared/search-field/search-field';
import { basePerformError } from '../../../helpers/error-helpers';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { IAnswer } from '../../../models/answer';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { RouteComponentProps } from 'react-router-dom';
import { Select } from '../../../components/shared/select/select';
import moment from 'moment';
import styles from './response-list.module.scss';


export function ResponseList(props: RouteComponentProps) {
    const history = useHistory();
    const [status, setStatus] = useState<string | undefined>();
    const [searchText, setSearchText] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState<string | undefined>();

    const loadData = React.useCallback(async (page: number = 1) => {
        try {
            const uid = undefined;

            return await getAnswersPaged({
                page,
                uid,
                searchText,
                orderBy
            });
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [orderBy, searchText]);

    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Submission</th>
                        <th>Submitted At</th>
                        <th>Admin Submitted</th>
                        <th>Upvotes</th>
                        <th>Downvotes</th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (answer: IAnswer, i: number, count: number, reloadData: () => void) => (
            <tr key={answer.uid}>
                <td>
                    <div className={styles.markContainer}>
                        <div className={`${styles.mark} ${answer.flagStatus?.toLowerCase() !== 'open' ? '' : styles.disabled}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{answer.answer}</td>
                <td>{moment(answer.createdAt).format('MM/DD/YYYY h:mm a')}</td>
                <td>{answer.adminSubmitted ? "YES" : ""}</td>
                <td>{answer.upvoteCount}</td>
                <td>{answer.downvoteCount}</td>
                <td>
                    <LinkButton to={joinPath(props.match.url, answer.uid)} className="orange">
                        View
                    </LinkButton><br />
                </td>
            </tr>
        ),
        [],
    );

    return (<>

        <SearchField onChange={setSearchText} />

        <div className={styles.filters}>
          <div className={`${styles.filter} ${styles.filterService}`}>
            <span>Order By:</span>
            <Select
              emptyTitle=""
              data={["Latest", "Total_Votes", "Upvotes"]}
              onChange={(e) => setOrderBy(e.target.value)}
              value={orderBy}
            />
          </div>
        </div>

        <Grid<IAnswer>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
        />
    </>)
}
